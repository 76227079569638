<template>
  <div class="view-box">
    <h1 class="h1 text-center ">
      金标联盟Webview适配指导
    </h1>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="1">
      一、WebView使用建议
    </h2>
    <p class="p margin-b10">
      ● 基于App内容形式，合理配置WebView组件，保证WebView随应用从折叠到展开全屏，有合理的窗口展示。
    </p>
    <p class="p margin-b10">
      ● 双屏到折叠状态切换，建议三方应用动态监听窗口变化，通知WebView刷新页面
    </p>
    <p class="p margin-b10">
      ● 合理配置WebView设置项，保证自适应显示效果
    </p>
    <div class="code margin-b20">
      <p class="p ">// WebSettings设置</p>  
      <p class="p ">
        // Sets whether the WebView should enable support for the "viewport" 
        <br>
        HTML meta tag or should use a wide viewport.建议置true
      </p>  
      <p class="p ">public abstract void setUseWideViewPort (boolean use)</p>  
      <p class="p ">
        // Sets whether the WebView loads pages in overview mode, that is, 
        <br>
        zooms out the content to fit on screen by width.建议置true
      </p>  
      <p class="p ">public abstract void setLoadWithOverviewMode (boolean overview)</p>  
    </div>
    <p class="p margin-b10">
      ● 合理获取User-Agent用于设置页面，常见UA及设置接口如下
    </p>
    <div class="code margin-b10">
      <p class="p ">
        // Sets the WebView's user-agent string. If the string is null or empty, the 
        <br>
        system default value will be used.
      </p>  
      <p class="p ">
        // 常用PC UA：Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 
        <br>
        (KHTML, like Gecko) Chrome/102.0.5005.62 Safari/537.36
      </p>  
      <p class="p ">
        // 常用mobile UA：Mozilla/5.0 (Linux; Android 6.0; Nexus 5
        <br>
        Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) 
        <br>
        Chrome/102.0.5005.62 Mobile Safari/537.36
      </p>  
      <p class="p ">
        // 常用Tablat UA: Mozilla/5.0 (Linux; Android 4.3; Nexus 7 Build/JSS15Q)
        <br>
        AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36
        <br>
        public abstract void setUserAgentString (String ua)
      </p>   
    </div>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-1">
      1.1 H5页面设计
    </h3>
    <p class="p margin-b20">
      基于响应式Web设计来实现界面的显示和布局是目前主流的H5页面设计方式，但也存在问题。由于没有对折叠屏的窗口变化进行合理分析，可能会在展开态存在如下界面显示问题：
    </p>
    <p class="p margin-b10">
      ● 界面元素等比放大，显示效果差：如字体太大、图片太大/模糊等。
    </p>
    <p class="p margin-b10">
      ● 界面元素延展到界面外，无法操作：如广告框的关闭按钮显示在界面外，无法关闭。
    </p>
    <p class="p margin-b10">
      ● 界面内容重叠、错位：如输入框中文字部分显示被截断。
    </p>
    <p class="p margin-b20">
      为了避免出现如上基础体验问题，建议前端开发者在H5的设计和开发采用以下的建议：
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-1-1">
      1.1.1 H5元素设计
    </h4>
    <p class="p margin-b10">
      ●  字体
    </p>
    <p class="p margin-b20">
      对折叠屏握持方式的调研发现，在展开态和折叠态下用户的使用距离没有明显的变化。避免展开时界面字体太大影响体验，因此不建议对展开态字体进行过多放大。展开态和折叠态可采用相同的字体大小或者在展开态字体放大适当的比例（如展开态为折叠态字体大小的1.2倍）。
    </p>
    <p class="p margin-b10">
      ●  图标
    </p>
    <p class="p margin-b20">
      对于通常的图标元素，建议展开折叠时采用跟字体大小一样的处理规则，如果字体保持一样，则图标也要保持一样的大小；如果字体在展开时少量放大，图标也应该采用相同的放大比例显示。
    </p>
    <p class="p margin-b10">
      ●  图片/视频
    </p>
    <p class="p margin-b10">
      图片作为信息表达的重要手段，在H5页面设计中应用非常广泛。不同的屏幕尺寸和分辨率会给网页图片带来更高的要求，常见的显示问题有：
    </p>
    <p class="p margin-b10 margin-l30">
        ● <b>美术设计问题</b>-更改显示的图像以适应不同的图像显示尺寸。例如，如果在大屏浏览器上的一个网站上显示一张大的、横向的照片，照片中央有个人，然后当在移动端小屏浏览器上浏览这个网站时，照片会缩小，这时照片上的人会变得非常小，看起来会很糟糕。这种情况可能在移动端显示一个更小的肖像图会更好，这样人物的大小看起来更合适。 
    </p>
    <p class="p margin-b20 margin-l30">
        ● <b>分辨率切换问题</b>-相同的尺寸，不同的分辨率。大小屏需要不同分辨率的图片
    </p>
    <p class="p margin-b10">
      以上两个问题都可以借助自适应图片来解决，提供包含多个版本图片的图集，借助CSS媒体查询按需选择。
    </p>
    <p class="p margin-b10">
      在解决上述问题的同时，图片在页面中的作用也不尽相同，我们把页面中的图片分为两种类型，一种是运营类图片，通常运营广告或分类入口；另一类是具体内容的图片，是内容消费的主体。不同类型的图片建议采用不同的处理方式。
    </p>
    <p class="p margin-b10 margin-l30">
      ● <b>运营类图片/视频</b>：针对运营类图片/视频，建议跟随字体缩放的比例进行缩放，如果字体保持一样，则此类图片/视频也保持一样的大小。为避免展开态运营类图片/视频，等比放大导致一屏幕显示内容过少。建议运营类图片/视频在展开态下，增加运营内容的显示列数；若无法增加显示列数，则运营类图片/视频等比放大时，最高不超过屏幕的1/2高度。
    </p>
    <p class="p margin-b20 margin-l30">
      ● <b>具体内容图片/ 视频</b>：对于具体内容图片/视频，可随着展开折叠进行适当比例的缩放，为了避免展开时图片/视频等比放大造成浏览效率的过度降低，建议在展开态采用内容扩展布局，增加显示列数，例如折叠态下双列显示，在展开态下三列或四列显示。对于单图/视频布局，展开态图片/视频等比放大时，建议最高不超过1屏幕高度。
    </p>
    <p class="p margin-b10">
      ●  弹窗
    </p>
    <p class="p margin-b20">
      建议展开态和折叠态h5弹窗保持相同的大小，或者按照字体大小的缩放比例进行等比缩放。
    </p>
    <p class="p margin-b10">
      ●  其他页面元素
    </p>
    <p class="p margin-b20">
      页面中的其他界面元素，如按钮、输入框等，建议展开折叠时高度按照字体大小的缩放比例进行缩放，宽度根据元素本身的响应式规则进行设计，部分元素（如按钮）保持等比缩放，部分元素（如列表、输入框）对宽度进行适当延伸，最宽不超过整个窗口的宽度。
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-1-2">
      1.1.2 CSS度量单位使用
    </h4>
    <p class="p margin-b20">
      响应式WEB设计的9项基本原则对设计Web页面具有极好的参考作用，这里我们主要对两种折叠态窗口的CSS度量单位设置提供部分建议。
    </p>
    <p class="p margin-b20">
      在通过CSS设置H5页面元素大小时，常用的响应式单位有rem，vw，vh，px：
    </p>
    <p class="p margin-b10">
      ●  rem（font size of the root element）是指相对于根元素的字体大小的单位。通常开发者会依赖设备窗口的宽度来计算rem的基准，因此以rem设置的实际长度在折叠屏展开态是折叠态的约两倍。
    </p>
    <p class="p margin-b10">
      ● vw（viewpoint width）, 视窗宽度，通常设置为
    </p>
    <div class="code margin-b10">
      <p class="p ">&lt; meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" ></p>  
    </div>
    <p class="p margin-b10">
      由于viewport的宽度被设置为设备窗口的宽度，因此以vw为单位的元素的实际长度在折叠屏展开态也是折叠态的约两倍。
    </p>
    <p class="p margin-b10">
      ● px，CSS中定义的px是相对于显示器屏幕分辨率而言的。因此px能够支持网页在移动端的良好展示，并且不会受到折叠屏展开、折叠状态变化的影响。
    </p>
    <p class="p margin-b20">
      综上所述，结合各种场景，建议如下:
    </p>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <td>
          <p></p>
        </td>
        <td>
          <p>折叠态</p>
        </td>
        <td >
          <p>展开态</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>无需缩放的场景	</p>
        </td>
        <td>
          <p>px为单位</p>
        </td>
        <td >
          <p>	px为单位</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>使用rem的场景</p>
        </td>
        <td>
          <p>
            <img :src="configBaseIMG+'/activityAdapterFile/image11.png'" alt="">
          </p>
        </td>
        <td >
          <p>
            <img :src="configBaseIMG+'/activityAdapterFile/image12.png'" alt="">
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>使用vw的场景	</p>
        </td>
        <td>
          <p>&lt; meta name="viewport" content="width=device-width" ></p>
        </td>
        <td >
          <p>&lt; meta name="viewport" content="width=device-width/x" ></p>
        </td>
      </tr>
    </table>
    <p class="p margin-b10">
      ● 无需缩放的场景，如字体元素，建议统一使用px作为长度单位。
    </p>
    <p class="p margin-b10">
      ● 由于展开态屏幕宽度接近于折叠态屏幕宽度的两倍，因此rem基准值和vw场景下content需要引入参数x进行一定比例的换算，x的值建议为2，应用可以根据应用界面元素的大小进行微调。 ƒ 是在未考虑折叠屏的情况下，网页开发者自行定义的用于定义1rem长度标准的参数值（例如，ƒ=10）。
    </p>
    <p class="p margin-b10">
      ● 使用rem的场景， clientWidth为document.body.clientWidth是网页的可视区域的宽度。
    </p>
    <p class="p margin-b10">
      ● 使用vw的场景，建议在展开态时，使用代码动态修改viewport的配置内容，将width设置按参数x进行比例换算。
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-2">
      1.2 H5页面布局设计
    </h3>
    <p class="p margin-b10">
      这里针对的是折叠展开带来的WebView窗口扩大，随之产生的页面布局适配问题。
    </p>
    <p class="p margin-b10">
      折叠屏展开态下屏幕宽度变宽，为折叠屏提供更高效的信息展示或更加沉浸的内容浏览提供了机会。
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-2-1">
      1.2.1 常见布局
    </h4>
    <p class="p margin-b20">
      H5页面布局在大屏下有如下4种基本适配方式：
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image13.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image13.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p ">
      ● 内容居中显示（内容不变，将显示位置居中）
    </p>
    <p class="p ">
      ● 内容x轴拉升（Y轴保持不变，X轴横向拉升，比较适合列表型页面）
    </p>
    <p class="p ">
      ● 内容等比缩放（XY轴等比缩放显示，简单快速适配方式）
    </p>
    <p class="p ">
      ● 内容扩展（使用新的内容补充多出来的空间，适合图片运营类页面）
    </p>
    <p class="p margin-b20">
      ● 内容流动（调整内容模块去补充多出来的空间，适合模块化页面)
    </p>
    <p class="p margin-b10">
      前端开发者可根据网页内容做不同形式的布局适配。现在的css，UI框架等都已经考虑到了适配不同屏幕分辨率的问题，实际项目中可以采用以下方案：
    </p>
    <p class="p ">
      ● Flexible Box 模型
    </p>
    <p class="p ">
      ● Grid 网格布局
    </p>
    <p class="p margin-b10">
      ● Columns 栅格系统（往往需要依赖某个UI库，如Bootstrap）
    </p>
    <p class="p margin-b20">
      要实现这些方案，可以借助 CSS Media Query 和 Container Queries。
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-2-2">
      1.2.2 基于视窗大小的CSS Media Query
    </h4>
    <p class="p margin-b20">
      以上布局都可以使用基于视窗可视区域大小的 CSS Media Query。利用 CSS Media Query在不同的终端上提供布局的切换。
    </p>
    <div class="code margin-b20">
      <p class="p ">/* Mobile First */</p>  
      <p class="p ">.card {</p> 
        <p class="p "><span class="nbsp"></span> display: flex;</p> 
        <p class="p "><span class="nbsp"></span> flex-wrap: wrap;</p> 
        <p class="p "><span class="nbsp"></span> gap: 10px;</p> 
      <p class="p ">}</p> 
      <br>
      <p class="p ">/* Tablet */</p>  
      <p class="p ">@media (min-width: 700px) {</p> 
        <p class="p "><span class="nbsp"></span> .card--vertical {</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>  gap: 20px</p> 
        <p class="p "><span class="nbsp"></span> }</p> 
      <p class="p ">}</p> 
      <br>
      <p class="p ">/* Laptop and Desktop */</p>  
      <br>
      <p class="p ">@media (min-width: 1024px) {</p> 
        <p class="p "><span class="nbsp"></span> .card--featured {</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>   position: relative</p> 
        <p class="p "><span class="nbsp"></span>}</p> 
      <br>
      <p class="p "><span class="nbsp"></span> .card--featured .card__thumb {</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>  position: absolute;</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>  inset: 0;</p> 
        <p class="p "><span class="nbsp"></span>}</p> 
      <p class="p ">}</p> 
      <br>
    </div>
    <p class="p margin-b20">
      虽然能满足Web页面布局大部分场景，但也相对丧失了一些其他能力，比如说将响应式的样式注入到组件本身的能力。换句话说，基于视窗宽度查询构建的响应式Web页面，尤其是响应式组件，其能力是有限的，开发者只能在视窗宽度大于某个特定值时（断点） 使用一个组件的特定样式。需H5页面设计者自行评估。
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-2-3">
      1.2.3 组件驱动式Web设计-CSS Container Queries
    </h4>
    <p class="p margin-b10">
      Container Queries 是通过使用容器类型属性（container-type或 container）指定要能的查询类型来建立的。适用于其后代的样式规则可以通过使用 @container 条件组规则对其进行查询来设定条件。
    </p>
    <p class="p margin-b20">
      相比于媒体查询，容器查询为响应式设计提供了一种更加动态的方法。组件将根据容器而不是视口进行响应式的信息展示。
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image14.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image14.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      容器查询特性的出现，使得前端开发者可以不再局限于视窗断点来调整布局或UI样式，还可以基于容器断点来调整布局或UI。换句话说，媒体查询是一种宏观的布局（Macro Layout），可以用于整体页面布局；而容器查询可以调整组件的每个元素，创建了一种微观的布局（Micro Layout）。
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image15.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image15.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      推荐前端开发者结合CSS Media Query 和 Container Queries 各自的优势，提供更好的内容体验。
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="2">
      二、折叠屏应用WebView组件适配问题速览
    </h2>
    <p class="p">
      目标：针对现有应用，实施最少改动以提升其在折叠屏手机上的基础体验。
    </p>
    <p class="p margin-b20">
      常见问题及建议：
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-1">
      2.1 WebView组件未自适应屏幕，无法在大屏模式下全屏展示
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image16.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image16.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      建议：目前未设置自适应窗口的App在折叠屏展开模式下不能全屏展示，保持原有比例，需开发者尽快适配，为用户在大屏模式下提供更丰富的内容。
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-2">
      2.2 WebView自适应屏幕，展示网页基于非自适应写法导致显示放大
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image17.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image17.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      建议：基于网页内容保持WebView组件单\半屏显示，可参考1.3-布局设计中的“内容流动”布局.。过度解决方案：通过UA设置折叠屏展开模式为PC或Tablet模式折叠态设置mobile模式，或者暂时以浮窗显示，也需应用适配，设置WebView页面的窗口大小居中浮窗显示。
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-3">
      2.3 WebView自适应屏幕且展示网页基于响应式写法，但显示异常（图片字体大小问题）
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400 margin-r20"
        :src="configBaseIMG+'/activityAdapterFile/image18.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image18.png',
        ]"
      >
      </el-image>
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image19.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image19.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p ">
      建议：
    </p>
    <p class="p ">
      1、 可参考1.2-H5页面基础设置中的建议对网页进行修改，需网页开发者修改。
    </p>
    <p class="p margin-b20">
      2、 断点设计时，常用的一些断点需要考虑厂商设备参数如分辨率的细微差异，适当调整范围做好兼容；
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-4">
      2.4 WebView不能响应双屏到折叠切换，内容无法正常显示
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image20.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image20.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p ">
      App初始状态为折叠屏展开，切换到单屏后图标大小、显示区域异常。
    </p>
    <p class="p margin-b20">
      建议：应用监听窗口变化主动通知刷新WebView，需应用适配。
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-5">
      2.5 WebView横竖屏时弹窗内容显示不全
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image21.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image21.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      建议：通过监听设备的横屏或者竖屏,来动态设置原网页的显示比例,从而让页面展示全
    </p>
  </div>
</template>

<script>
export default {
  props: ['scrollContainer'],
  data() {
    return {
    };
  },
  created() {
    
  },
};
</script>

<style scoped lang="scss">
.h1 {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p {
  display: block;
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
.a {
  color: rgb(64, 158, 255);
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>