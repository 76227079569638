<template>
  <div class="documentsBox">
    <div class="menu-navBox">
      <el-menu
        class="menu-nav"
        background-color="#282828"
        text-color="#81818C"
        active-text-color="#fff"
        :default-active="activeRouter"
        unique-opened
        >
        <!-- router -->
        <div v-for="(item,index) in documentData" :key="index" >
          <el-submenu :index="index+''">
            <template slot="title">
              <div class="ellipsis">{{item.name}}</div>
            </template>
            <el-menu-item-group v-if="item.child.length">
              <el-menu-item :index="item2.href" v-for="(item2,index2) in item.child" :key="index2" @click="goHref(item2.href)">
                <div class="ellipsis">{{item2.name}}</div>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </div>
      </el-menu>
      
      <div class="menu-content" >
        <div class="padding20">
          <el-breadcrumb class="breadcrumb" separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>标准文档</el-breadcrumb-item>
            <el-breadcrumb-item>{{activeMenuName}}</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="documentsContent">
            <router-view class="page-box"></router-view>
          </div>
          <ICP bgcClor="#fafafa" color="#bdc2c7"></ICP>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ICP from "@/components/ICP";
import { singleMembersSever } from "@/common/api-config";
import { documentData } from "@/common/VO/homeVO.js";
export default {
  components: {
    ICP
  },
  created() {
    this.activeRouter = this.$route.meta.menu
      ? this.$route.meta.menu
      : this.activeRouter; //初始化选中路由
    this.activeMenuName= this.$route.meta.menuName
      ? this.$route.meta.menuName
      : ''; //初始化选中面包屑
  },
  watch: {
    $route(to, from) {
      this.activeRouter = to.meta.menu ? to.meta.menu : to.path; //切换选中路由
      this.activeMenuName= this.$route.meta.menuName ? this.$route.meta.menuName : ''; //切换选中面包屑
    }
  },
  data() {
    return {
      documentData: documentData(),
    };
  },
  methods:{
    goHref(href){
      if(this.$route.query.lang){
        this.$router.push({ path:href,query:{lang:this.$route.query.lang}});
      }else{
        this.$router.push({ path:href});

      }
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-scrollbar__view {
  height: 100%;
}
.documentsBox {
  margin-top: 80px;
  height: calc(100% - 80px); //减头高度
  .menu-navBox {
    display: flex;
    height: 100%;
    .menu-nav {
      width: 250px;
      padding: 20px 0;
      min-height: calc(100% - 80px); //减头高度
      .menu-navItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 40px!important;
        height: 70px;
        line-height: 70px;
        font-size: 18px;
        .el-icon-arrow-right{
          margin-top: -2px;
        }
      }
      .is-active {
        color: #fff !important;
      }
    }
    .menu-content {
      width: 100%;
      flex: 1;
      background: #fafafa;
      .padding20{
        height: 100%;
        padding: 0px 20px ;
        .breadcrumb{
          padding: 20px 10px;
          background: #fafafa;
          z-index: 9;
        }
        .documentsContent{
          height: calc(100% - 110px); //减头高度
          border: 1px solid #EEEEEE;
          border-radius: 5px;
          background: #fff;
          .page-box{
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
    .documentsBox {
      .menu-nav{
        display: none;
      }
      .documentsContent{
        padding: 20px!important;
         /deep/ img{
          width: 100%;
         }
      }
    }
}
</style>

