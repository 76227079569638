export const documentData = function () {
  return [
    {
      name: "金标应用标准",
      href:"/documentsBase/compatibleFile",
      child:[
        {
          name: "兼容性标准",
          href:'/documentsBase/compatibleFile',
        },
        {
          name: "稳定性标准",
          href:'/documentsBase/stabilityFile',
        },
        {
          name: "性能标准",
          href:"/documentsBase/performanceFile",
        },
        {
          name: "功耗标准",
          href:"/documentsBase/powerWasteFile",
        },
        {
          name: "安全标准",
          href:"/documentsBase/safetyFile",
        },
        {
          name: "个人信息保护标准",
          href:"/documentsBase/privacyFile",
        }
      ]
    },
    {
      name: "其他文档",
      href:"/documentsBase/earthquakeEarlyWarningFile",
      child:[
        {
          name: "地震预警服务规范",
          href:"/documentsBase/earthquakeEarlyWarningFile",
        },
        {
          name: "ITGSA大屏设备应用适配白皮书",
          href:"/documentsBase/whiteBookFile",
        },
        {
          name: "附录一 金标联盟Activity嵌入适配指导",
          href:"/documentsBase/activityAdapterFile",
        },
        {
          name: "附录二 金标联盟Webview适配指导",
          href:"/documentsBase/webviewAdapterFile",
        },
        {
          name: "附录三 金标联盟android T 无androidX实现悬停监听",
          href:"/documentsBase/androidXOnHoverFile",
        },
      ]
    }
  ]
}
export const homeData = function () {
  return [
    {
      name: "兼容性标准",
      href:'/documentsBase/compatibleFile',
    },
    {
      name: "稳定性标准",
      href:'/documentsBase/stabilityFile',
    },
    {
      name: "性能标准",
      href:"/documentsBase/performanceFile",
    },
    {
      name: "功耗标准",
      href:"/documentsBase/powerWasteFile",
    },
    {
      name: "安全标准",
      href:"/documentsBase/safetyFile",
    },
    {
      name: "个人信息保护标准",
      href:"/documentsBase/privacyFile",
    },
    {
      name: "地震预警服务规范",
      href:"/documentsBase/earthquakeEarlyWarningFile",
    },
    {
      name: "ITGSA大屏设备应用适配白皮书",
      href:"/documentsBase/whiteBookFile",
    },
  ]
}
