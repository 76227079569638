<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="lang=='English'?navFixed2:navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <div class="lang-box">
      <el-radio-group v-model="lang" size="medium" @change="goHref">
        <el-radio-button label="中文"></el-radio-button>
        <el-radio-button label="English"></el-radio-button>
      </el-radio-group>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <webviewAdapterFile_enUS v-if="lang=='English'" :scrollContainer="scrollContainer"></webviewAdapterFile_enUS>
      <webviewAdapterFile_zhCH v-else :scrollContainer="scrollContainer"></webviewAdapterFile_zhCH>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
import webviewAdapterFile_zhCH from "@/components/documentsBase/template/webviewAdapterFile_zhCH";
import webviewAdapterFile_enUS from "@/components/documentsBase/template/webviewAdapterFile_enUS";
export default {
  components: { Nav,webviewAdapterFile_zhCH,webviewAdapterFile_enUS},
  metaInfo: {
    title: "ITGSA | 移动智能终端生态联盟 | 地震预警服务规范",
    meta: [
      {
        name: "keywords",
        content: "ITGSA,生态联盟,金标认证,地震预警服务规范",
      },
      {
        name: "description",
        content:
          "Android应用的稳定性是构建Android应用体验的重要一环，直接影响用户体验，本标准旨在构建一套Android应用稳定性测试衡量方法，牵引生态内所有应用的稳定性质量改进，构建共同的稳定和体验良好的应用生态。",
      },
    ],
  },
  data() {
    return {
      scrollContainer: HTMLElement,
      lang: this.$route.query.lang?this.$route.query.lang:'中文',
      navFixed: [
          {
            name: "一、 WebView使用建议",
            isActive:0,
            class:'#1',
            child:[
              {
                name: "1.1 H5页面设计",
                isActive:null,
                class:'#1-1',
                child:[
                  {
                    name: "1.1.1 H5元素设计",
                    isActive:null,
                    class:'#1-1-1',
                  },
                  {
                    name: "1.1.2 CSS度量单位使用",
                    isActive:null,
                    class:'#1-1-2',
                  },
                ],
              },
              {
                name: "1.2 H5页面布局设计",
                isActive:null,
                class:'#1-2',
                child:[
                  {
                    name: "1.2.1 常见布局",
                    isActive:null,
                    class:'#1-2-1',
                  },
                  {
                    name: "1.2.2 基于视窗大小的CSS Media Query",
                    isActive:null,
                    class:'#1-2-2',
                  },
                  {
                    name: "1.2.3 组件驱动式Web设计-CSS Container Queries",
                    isActive:null,
                    class:'#1-2-3',
                  },
                ],
              },
            ]
          },
          {
            name: "二、 折叠屏应用WebView组件适配问题速览",
            isActive:null,
            class:'#2',
            child:[
              {
                name: "2.1 WebView组件未自适应屏幕，无法在大屏模式下全屏展示",
                isActive:null,
                class:'#2-1',
              },
              {
                name: "2.2 WebView自适应屏幕，展示网页基于非自适应写法导致显示放大",
                isActive:null,
                class:'#2-2',
              },
              {
                name: "2.3 WebView自适应屏幕且展示网页基于响应式写法，但显示异常（图片字体大小问题）",
                isActive:null,
                class:'#2-3',
              },
              {
                name: "2.4 WebView不能响应双屏到折叠切换，内容无法正常显示",
                isActive:null,
                class:'#2-4',
              },
              {
                name: "2.5 WebView横竖屏时弹窗内容显示不全",
                isActive:null,
                class:'#2-5',
              },
            ]
          },
      ],
      navFixed2: [
          {
            name: "I. Suggestions for use of WebView",
            isActive:0,
            class:'#1',
            child:[
              {
                name: "1.1 H5 page design",
                isActive:null,
                class:'#1-1',
                child:[
                  {
                    name: "1.1.1 H5 element design",
                    isActive:null,
                    class:'#1-1-1',
                  },
                  {
                    name: "1.1.2 Use of CSS measurement units",
                    isActive:null,
                    class:'#1-1-2',
                  },
                ],
              },
              {
                name: "1.2 H5 page layout design",
                isActive:null,
                class:'#1-2',
                child:[
                  {
                    name: "1.2.1 Common layout",
                    isActive:null,
                    class:'#1-2-1',
                  },
                  {
                    name: "1.2.2 CSS Media Query based on window size",
                    isActive:null,
                    class:'#1-2-2',
                  },
                  {
                    name: "1.2.3 Component driven Web design -CSS Container Queries",
                    isActive:null,
                    class:'#1-2-3',
                  },
                ],
              },
            ]
          },
          {
            name: "II. A quick review of the adaptation problems of WebView component application for foldable screen ",
            isActive:null,
            class:'#2',
            child:[
              {
                name: "2.1 The WebView component does not adapt to the screen and cannot display in full screen mode",
                isActive:null,
                class:'#2-1',
              },
              {
                name: "2.2 For WebView adaptive screen, pages are displayed based on non adaptive writing method, which results in enlarged display. ",
                isActive:null,
                class:'#2-2',
              },
              {
                name: "2.3 WebView is adaptive to the screen and the display page is based on the responsive writing method, but the display is abnormal (the font size of the picture is incorrect.)",
                isActive:null,
                class:'#2-3',
              },
              {
                name: "2.4 WebView cannot respond to the switching from dual-screen to folded screen, and the content cannot be displayed normally.",
                isActive:null,
                class:'#2-4',
              },
              {
                name: "2.5 The pop-up window of WebView fails to display complete content when in landscape mode or portrait screen mode.",
                isActive:null,
                class:'#2-5',
              },
            ]
          },
      ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    goHref(value){
      this.$router.push({ path:'/documentsBase/webviewAdapterFile',query:{lang:value}});
    },
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">

/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .lang-box{
    position: absolute;
    right: 0;
    top: -45px;
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
    .page-box{
      .view-box{
        padding: 10px;
        .w400, .w750{
          width: 100% !important;
        }
        .p{
          padding-left: 1rem !important;
          word-break:break-all;
        }
      }
    }
}
</style>
