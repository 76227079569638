<template>
  <div class="view-box">
    <h1 class="h1 text-center ">
      ITGSA Instructions for Webview Adaptation
    </h1>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="1">
      I. Suggestions for use of WebView
    </h2>
    <p class="p margin-b10">
      ● The WebView components should be reasonably configured based on the content form of App to ensure that the WebView gives a reasonable window display from folded state to flat state with App.
    </p>
    <p class="p margin-b10">
      ● For switching from dual-screen to folded state, three-party App dynamic listening window change is recommended and WebView should be notified to refresh the page.
    </p>
    <p class="p margin-b10">
      ● WebView settings should be reasonably configured to ensure adaptive display effect.
    </p>
    <div class="code margin-b20">
      <p class="p ">// Setting of WebSettings</p>  
      <p class="p ">
        // Sets whether the WebView should enable support for the "viewport" 
        <br>
        HTML meta tag or should use a wide viewport.“true”is recommended for setting
      </p>  
      <p class="p ">public abstract void setUseWideViewPort (boolean use)</p>  
      <p class="p ">
        // Sets whether the WebView loads pages in overview mode, that is, 
        <br>
        zooms out the content to fit on screen by width.“true”is recommended for setting
      </p>  
      <p class="p ">public abstract void setLoadWithOverviewMode (boolean overview)</p>  
    </div>
    <p class="p margin-b10">
      ● User-Agent should be reasonably obtained for setting pages. Generally used UA and setting interfaces are as follows
    </p>
    <div class="code margin-b10">
      <p class="p ">
        // Sets the WebView's user-agent string. If the string is null or empty, the 
        <br>
        system default value will be used.
      </p>  
      <p class="p ">
        // PC UA are generally used：Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 
        <br>
        (KHTML, like Gecko) Chrome/102.0.5005.62 Safari/537.36
      </p>  
      <p class="p ">
        // mobile UA are generally used：Mozilla/5.0 (Linux; Android 6.0; Nexus 5
        <br>
        Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) 
        <br>
        Chrome/102.0.5005.62 Mobile Safari/537.36
      </p>  
      <p class="p ">
        // Tablat UA are generally used: Mozilla/5.0 (Linux; Android 4.3; Nexus 7 Build/JSS15Q)
        <br>
        AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36
        <br>
        public abstract void setUserAgentString (String ua)
      </p>   
    </div>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-1">
      1.1 H5 page design
    </h3>
    <p class="p margin-b20">
      Implementing interface display and layout based on responsive Web design is the mainstream method for H5 page design. However some problems remain unsolved. If window changes of foldable screen are not reasonably analyzed, the following interface display problems may exist in expanded state:
    </p>
    <p class="p margin-b10">
      ● The interface elements are magnified proportionally, which results in poor display effects: such as too large/ fuzzy font and pictures.
    </p>
    <p class="p margin-b10">
      ● The interface elements are extended outside, no operation can be implemented: For example, the Close button of the advertising box is displayed outside the interface, which makes Close impossible.
    </p>
    <p class="p margin-b10">
      ● Overlapping and misplacement of interface contents: For example, the display of text in input box is truncated. 
    </p>
    <p class="p margin-b20">
      In order to avoid above-mentioned basic experience problems, it is recommended that front-end developers adopt the following suggestions for the design and development of H5:
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-1-1">
      1.1.1 H5 element design
    </h4>
    <p class="p margin-b10">
      ●  Font
    </p>
    <p class="p margin-b20">
      It is revealed through the research on the holding mode of foldable screen that there is no significant change in users' using distance between the expanded and folded states. To avoid too much influence on user experience， it is not recommended to enlarge the font in the expanded state too much. The same font size can be used in both the unfolded and folded states, or the font in the expanded state can be only enlarged appropriately (for example, the font size in expanded state is 1.2 times of the size in folded state).
    </p>
    <p class="p margin-b10">
      ●  Icon
    </p>
    <p class="p margin-b20">
      For common icon elements, the same processing rules as those for font size are recommended when unfolding from folded state. If the font remains the same, the icon should also remain the same size; if the font is slightly enlarged when it is unfolded, the icon should also be displayed at the same magnification.
    </p>
    <p class="p margin-b10">
      ●  Picture/video
    </p>
    <p class="p margin-b10">
      As an important means of information expression, pictures are extensively used in H5 page design. Diverse screen sizes and resolutions bring higher requirements for web images. Common display problems include:
    </p>
    <p class="p margin-b10 margin-l30">
        ● <b>Art design problem</b> - The images to be displayed need to be changed to adapt to different image display sizes. For example, if a large, horizontal picture with a person in its central place is displayed on a website on a large screen browser, when browsing the website on a small screen browser on a mobile terminal, the picture will shrink, and the person in the picture will become very small. It looks terrible. For this case, it may be better to display a smaller portrait on the mobile terminal. In this way, the size of the character looks more appropriate.
    </p>
    <p class="p margin-b20 margin-l30">
        ● <b>Resolution switching problem</b> - Same size and different resolutions. Images with different resolutions are required for large and small screens
    </p>
    <p class="p margin-b10">
        The above-mentioned two problems can be solved with the help of adaptive pictures: providing a picture set containing multiple versions of pictures, and the CSS media query can be used to implement on-demand selection.
    </p>
    <p class="p margin-b10">
      On solving the above problems, the role of pictures in the page is also different. We classify the pictures in the page into two categories, one is operational pictures, usually operating advertisements or classified portals; the other category of pictures are those of specific content, which are the main part of content consumption. It is recommended that different types of pictures be processed in different ways.
    </p>
    <p class="p margin-b10 margin-l30">
      ● <b>Operational pictures/videos:</b>For operational pictures/videos, scaling according to font scaling ratio is recommended. If same size remains for the fonts, same size also remains for the pictures/videos. In order to avoid the case that the content of operational pictures/videos in the expanded state displayed on screen is too little due to scaling proportionally. It is recommended to increase the number of display columns of operational content in the expanded state of operational pictures/videos; if the number of display columns cannot be increased, the operation pictures/videos shall not exceed 1/2 of the height of the screen when they are scaled up proportionally.
    </p>
    <p class="p margin-b20 margin-l30">
      ● <b>Pictures/videos with specific content: </b> For pictures/videos with specific content, they can be scaled appropriately as they are expanded from folding state. To avoid excessive decline of browsing efficiency caused by scaling pictures/videos with equal proportion when they are unfolded, content scaling layout is recommended for the expanded state to increase the number of display columns, such as double-column display in the folded state and three- or four-column display in the expanded state. For single picture/video layout, it is recommended that the maximum height should not exceed one screen height when the pictures/videos in the expanded state are scaled up proportionally.
    </p>
    <p class="p margin-b10">
      ●  Pop-up window
    </p>
    <p class="p margin-b20">
      It is recommended to maintain the same size of the h5 pop-up window in the unfolded and folded states, or to scale the size proportionally according to the fonts.
    </p>
    <p class="p margin-b10">
      ●  Other page elements
    </p>
    <p class="p margin-b20">
      For other interface elements in the page, including buttons and input boxes, it is recommended to scale their height according to the scaling ratio of the font size as they are expanded from folding state, and the width should be designed according to the responsive rules of the element itself. Some elements (such as buttons) should be scaled proportionally, while width of some elements (such as lists and input boxes) should be expanded appropriately, and the maximum width should not exceed the width of the entire window.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-1-2">
      1.1.2 Use of CSS measurement units
    </h4>
    <p class="p margin-b20">
      The nine basic principles for responsive Web design are excellent references for designing Web page. We will mainly offer some suggestions on the setting of CSS measurement units for two types of windows in folded state.
    </p>
    <p class="p margin-b20">
      When setting the size of H5 page elements through CSS, the common responsive units are rem, vw, vh, px:
    </p>
    <p class="p margin-b10">
      ●  rem (font size of the root element) means the unit of font size relative to the root element. Generally, developers rely on the width of device window to calculate the rem benchmark. Therefore, the actual length of foldable screen in flat state set by rem is about twice that of the screen in folded state.
    </p>
    <p class="p margin-b10">
      ● vw (viewpoint width), the window width. It is usually set as
    </p>
    <div class="code margin-b10">
      <p class="p ">&lt; meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" ></p>  
    </div>
    <p class="p margin-b10">
      Since the width of the viewport is set as the width of the device window, the actual length of the element in vw in flat state of the foldable screen is about twice of the length in folded state.
    </p>
    <p class="p margin-b10">
      ● px, px defined in CSS is relative to the display screen resolution. Therefore, px can support good display of web pages on mobile terminal, and is not be affected by the expanded and folded state changes of the foldable screen.
    </p>
    <p class="p margin-b20">
      In summary, the suggestions are provided below in combination with various scenarios:
    </p>
    <table class="p  margin-b30" cellspacing="0" cellpadding="0">
      <tr>
        <td>
          <p></p>
        </td>
        <td>
          <p>Folded State </p>
        </td>
        <td >
          <p>Expanded state</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Scenario without scaling	</p>
        </td>
        <td>
          <p>px is used as the unit.</p>
        </td>
        <td >
          <p>px is used as the unit.</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Scenario using rem</p>
        </td>
        <td>
          <p>
            <img :src="configBaseIMG+'/activityAdapterFile/image11.png'" alt="">
          </p>
        </td>
        <td >
          <p>
            <img :src="configBaseIMG+'/activityAdapterFile/image12.png'" alt="">
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Scenario using vw	</p>
        </td>
        <td>
          <p>&lt; meta name="viewport" content="width=device-width" ></p>
        </td>
        <td >
          <p>&lt; meta name="viewport" content="width=device-width/x" ></p>
        </td>
      </tr>
    </table>
    <p class="p margin-b10">
      ● For scenarios in which scaling is not required, such as font elements, it is recommended to use px as the length unit.
    </p>
    <p class="p margin-b10">
      ● Since the screen width in flat state is close to twice that of the screen in folded state, parameter x should be introduced to convert the rem benchmark value and the content in the vw scenario in a certain proportion. The recommended value of x is “2”, and in actual application, it can be adjusted slightly according to the size of the application interface elements. ƒ is a parameter value defined by the Web developer to define the 1rem length standard when foldable screen is not considered (for example, ƒ=10).
    </p>
    <p class="p margin-b10">
      ● For scenarios using rem, clientWidth as document.body.clientWidth is the width of the visible area of the web page.
    </p>
    <p class="p margin-b10">
      ● For scenarios using vw, it is recommended to dynamically modify the configuration content of the viewport in expanded state with code to calculate the width setting according to parameter x.
    </p>
    <h3 class="h3 margin-t30 margin-b20 nav-title" id="1-2">
      1.2 page layout design
    </h3>
    <p class="p margin-b10">
      This is for the page layout adaptation problems caused by the expansion of the WebView window due to expansion of folded screen.
    </p>
    <p class="p margin-b10">
      The screen becomes wider when the foldable screen is expanded, providing an opportunity for foldable screen to provide more efficient information display or more immersive content browsing.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-2-1">
      1.2.1 Common layout
    </h4>
    <p class="p margin-b20">
      There are four basic adaptation modes under large screen for the H5 page layout:
    </p>
    <p class="margin-b20">
      <!-- <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image13.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image13.png',
        ]"
      >
      </el-image> -->
      
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="require('@/assets/img/documents/English/image13.png')"
        :preview-src-list="[
          require('@/assets/img/documents/English/image13.png'),
        ]"
      >
      </el-image>
    </p>
    <p class="p ">
      ● The content is displayed in the center (the content remains unchanged, wgile the display position is centered)
    </p>
    <p class="p ">
      ● The content is scaled up along the x axis (it remains unchanged along the Y axis, and is scaled up along the X axis horizontally. It is more suitable for list pages)
    </p>
    <p class="p ">
      ● Content is scaled proportionally (scaled proportionally along X axis and Y axis for display. It is a simple and fast adaptation mode) 
    </p>
    <p class="p ">
      ● Content expansion (supplement the extra space with new content, suitable for operational pages with pictures)
    </p>
    <p class="p margin-b20">
      ● Content movement (adjust content module to supplement the extra space, suitable for modular pages)
    </p>
    <p class="p margin-b10">
      Front end developers can develop different forms of layout adaptation according to web page content. The problem of adapting to different screen resolutions has been considered in current css and UI frameworks. The following solutions can be adopted in actual projects:
    </p>
    <p class="p ">
      ● Flexible Box model
    </p>
    <p class="p ">
      ● Grid layout
    </p>
    <p class="p margin-b10">
      ● Columns grid system (usually it depends on some UI library, such as Bootstrap)
    </p>
    <p class="p margin-b20">
      To implement above-mentioned solutions, you can use CSS Media Query and Container Queries.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-2-2">
      1.2.2 CSS Media Query based on window size
    </h4>
    <p class="p margin-b20">
      CSS Media Query based on the size of the window viewing area can be used for all the above layouts. CSS Media Query can be used to provide layout switching on different terminals.
    </p>
    <div class="code margin-b20">
      <p class="p ">/* Mobile First */</p>  
      <p class="p ">.card {</p> 
        <p class="p "><span class="nbsp"></span> display: flex;</p> 
        <p class="p "><span class="nbsp"></span> flex-wrap: wrap;</p> 
        <p class="p "><span class="nbsp"></span> gap: 10px;</p> 
      <p class="p ">}</p> 
      <br>
      <p class="p ">/* Tablet */</p>  
      <p class="p ">@media (min-width: 700px) {</p> 
        <p class="p "><span class="nbsp"></span> .card--vertical {</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>  gap: 20px</p> 
        <p class="p "><span class="nbsp"></span> }</p> 
      <p class="p ">}</p> 
      <br>
      <p class="p ">/* Laptop and Desktop */</p>  
      <br>
      <p class="p ">@media (min-width: 1024px) {</p> 
        <p class="p "><span class="nbsp"></span> .card--featured {</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>   position: relative</p> 
        <p class="p "><span class="nbsp"></span>}</p> 
      <br>
      <p class="p "><span class="nbsp"></span> .card--featured .card__thumb {</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>  position: absolute;</p> 
        <p class="p "><span class="nbsp"></span><span class="nbsp"></span>  inset: 0;</p> 
        <p class="p "><span class="nbsp"></span>}</p> 
      <p class="p ">}</p> 
      <br>
    </div>
    <p class="p margin-b20">
      Although most scenarios of Web page layout can be met, some other capabilities are lost relatively, such as the ability to inject responsive styles into components themselves. In other words, for responsive Web pages built based on viewport width queries, especially responsive components, their capabilities are limited. Developers can only use a specific style of a component when the viewport width is greater than a specific value (breakpoint). Evaluation needs to be performed by H5 page designers.
    </p>
    <h4 class="h4 margin-t30 margin-b20 nav-title" id="1-2-3">
      1.2.3 Component driven Web design -CSS Container Queries
    </h4>
    <p class="p margin-b10">
      Container Queries are created by using the container-type attribute (container type or container) to specify the query type needing to be enabled. Conditions for the style rules applicable to its descendants can be set by querying them with @ container condition group rules.
    </p>
    <p class="p margin-b20">
      Compared with media query, container query provides a more dynamic method for responsive design. The components will offer responsive information display based on the container rather than the viewport.
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image14.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image14.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      The appearance of container query feature enables front-end developers to adjust layout or UI style no longer limited to window breakpoints, but can also rely on container breakpoints. In other words, media query is a Macro Layout. It can be used for overall page layout; container query can be used to adjust each element of a component, creating a Micro Layout.
    </p>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w750"
        :src="configBaseIMG+'/activityAdapterFile/image15.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image15.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      It is recommended that front-end developers combine the benefits of CSS Media Query and Container Queries to provide better content experience.
    </p>
    <h2 class="h2 margin-t30 margin-b20 nav-title" id="2">
      二、A quick review of the adaptation problems of WebView component application for foldable screen 
    </h2>
    <p class="p">
      Objective: To implement minimum changes to improve basic experience on foldable-screen phones based on existing applications.
    </p>
    <p class="p margin-b20">
      Common problems and suggestions:
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-1">
      2.1 The WebView component does not adapt to the screen and cannot display in full screen mode
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image16.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image16.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      Suggestion: Apps without adaptive windows can't be displayed in full screen in flat mode of foldable screen, maintaining original ratio. Adaption work needs to be done by developers as soon as possible to offe users with richer content in large screen mode.
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-2">
      2.2 For WebView adaptive screen, pages are displayed based on non adaptive writing method, which results in enlarged display. 
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image17.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image17.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      Suggestion: For maintenance of single/ half screen display of WebView component based on web page content, please refer to "Content Flow" layout in Section 1.3 Layout Design. Solution for transition: Set the unfolding mode of foldable screen through UA as PC or Tablet mode, folding mode as mobile mode, or a floating window display mode is temporarily adopted. Adaptation also needs to be applied to set the window size of the WebView page to be centered for floating window display.
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-3">
      2.3 WebView is adaptive to the screen and the display page is based on the responsive writing method, but the display is abnormal (the font size of the picture is incorrect.)
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400 margin-r20"
        :src="configBaseIMG+'/activityAdapterFile/image18.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image18.png',
        ]"
      >
      </el-image>
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image19.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image19.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p ">
      Suggestion:
    </p>
    <p class="p ">
      1. The web page can be modified with reference to the suggestions in Section 1.2  ( basic settings of H5 page ). The modification needs to be n made by the web developers.
    </p>
    <p class="p margin-b20">
      2. During breakpoint design, for some commonly used breakpoints, subtle differences of manufacturer's equipment parameters such as resolution need to be taken into account, and range needs to be properly adjusted for compatibility;
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-4">
      2.4 WebView cannot respond to the switching from dual-screen to folded screen, and the content cannot be displayed normally.
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image20.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image20.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p ">
      The initial state of the App is flat mode of foldable screen. After switching to single-screen mode, the icon size and display area are abnormal.
    </p>
    <p class="p margin-b20">
      Suggestion: App should be able to listen window changes and initiate active notification for refreshing WebView, and adaptation should be applied.
    </p>
    <h3 class="h3 margin-t30 margin-b30 nav-title" id="2-5">
      2.5 The pop-up window of WebView fails to display complete content when in landscape mode or portrait screen mode.
    </h3>
    <p class="margin-b20">
      <el-image lazy :scroll-container="scrollContainer"
        class="w400"
        :src="configBaseIMG+'/activityAdapterFile/image21.png'"
        :preview-src-list="[
          configBaseIMG+'/activityAdapterFile/image21.png',
        ]"
      >
      </el-image>
    </p>
    <p class="p margin-b20">
      Suggestion: Dynamically set the display proportion of the original web page by listening landscape or portrait screen mode of the device, so that the page can display full content.
    </p>
  </div>
</template>

<script>
export default {
  props: ['scrollContainer'],
  data() {
    return {
    };
  },
  created() {
    
  },
};
</script>

<style scoped lang="scss">
.h1 {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p {
  display: block;
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
.a {
  color: rgb(64, 158, 255);
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>