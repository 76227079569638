<template>
    <div class="nav-box">
      <div v-for="(item,index) in navFixed" :key="index">
        <div>
          <p class="name" :view="item.class" :title="item.name" @click="onActive($event)">{{item.name}}</p>
        </div>
        <nav-child v-if="item.child && item.child.length" :navFixed="item.child"></nav-child>
      </div>
    </div>
</template>

<script>
  import Index from "@/components/documentsBase/Index";
  export default {
    name:'nav-child',
    props:['navFixed'],
    data() {
      return {
        
      };
    },
    methods: {
      onActive(event){
        if($(event.target).attr("view")){
          $('.nav-box .name').css("color","#000");;
          $(event.target).css("color","#1989fa");
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .nav-box{
    padding: 10px;
    & > div{
      padding-left: 10px;
      line-height: 30px;
    }
    .name{
      height: 30px;
      vertical-align: middle;
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name:hover{
      color: #1989fa;
      cursor: pointer;
    }
  }
</style>
